<template>
  <div>
    <div class="mx-2">
      <!-- <b-breadcrumb
        class="breadcrumb pb-2 pt-1 noprint"
        :items="breadcrumb_items"
      ></b-breadcrumb> -->
      <div style="display: flex">
        <div class="col" style="text-align: left">
          <button
            v-can="'mesh.add_modalityegressprofile'"
            v-if="allows_crud"
            class="btn btn-secondary btn-sm"
            @click="$bvModal.show(`new-modality-modal`)"
          >
            Agregar
            {{
              $getVisibleNames(
                "mesh.modalityegressprofile",
                false,
                "Modalidad del Perfil de Egreso"
              )
            }}
            <!-- Modalidad del Perfil de Egreso -->
          </button>
        </div>
      </div>
      <h3 class="mt-3">
        {{
          $getVisibleNames(
            "mesh.modalityegressprofile",
            false,
            "Modalidad del Perfil de Egreso"
          )
        }}
        <AlternativeNameButton
          :table_name="'mesh.modalityegressprofile'"
          :allows_crud="allows_crud"
        ></AlternativeNameButton>
      </h3>
      <GenericBTable
        v-if="searchModality.length > 0"
        :items="modalities_egress_profiles"
        :pagination="modalities_egress_profiles.length"
        :fields="modalitiesFields"
        :filter="input_search"
        :show_pagination="true"
        :search_filter="true"
        :columns_display="true"
        :allows_crud="allows_crud"
        :display_id="display_id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template #cell(actions)="row">
          <template v-if="allows_crud">
            <b-button
              variant="none"
              class="m-0 p-0 mr-2"
              v-b-tooltip.v-secondary.noninteractive="
                'Editar requisitos de horas y créditos'
              "
              @click="$bvModal.show(`limit-study-plan-modal-${row.item.id}`)"
            >
              <b-icon icon="journal-text" scale="1"></b-icon>
            </b-button>
            <button-edit
              @click="$bvModal.show(`edit-modality-modal-${row.item.id}`)"
              v-b-tooltip.v-secondary.noninteractive="
                `Editar ${$getVisibleNames(
                  'mesh.modalityegressprofile',
                  false,
                  'Modalidad del Perfil de Egreso'
                )}`
              "
            ></button-edit>
            <button-delete
              @click="askForDeleteModality(row.item.id)"
              v-b-tooltip.v-secondary.noninteractive="
                `Eliminar ${$getVisibleNames(
                  'mesh.modalityegressprofile',
                  false,
                  'Modalidad del Perfil de Egreso'
                )}`
              "
            ></button-delete>
          </template>
          <template v-else>
            <b-button
              variant="none"
              class="m-0 p-0 mr-2"
              v-b-tooltip.v-secondary.noninteractive="
                'Ver requisitos de horas y créditos'
              "
              @click="$bvModal.show(`limit-study-plan-modal-${row.item.id}`)"
            >
              <b-icon icon="journal-text" scale="1"></b-icon>
            </b-button>
          </template>
          <b-modal
            :id="`limit-study-plan-modal-${row.item.id}`"
            :title="`${
              !allows_crud ? 'VER' : 'EDITAR'
            } REQUISITOS DE HORAS Y CRÉDITOS`"
            size="xl"
            hide-footer
          >
            <LimitStudyPlan
              :modality_egress_profile_id="row.item.id"
              :allows_crud="
                allows_crud &&
                ((user_position &&
                  [1, 2, 3].includes(user_position.position) &&
                  [1, 2, 3].includes(user.groups[0])) ||
                  user.is_superuser)
              "
            ></LimitStudyPlan>
          </b-modal>
          <b-modal
            :id="`edit-modality-modal-${row.item.id}`"
            :title="`Editar ${$getVisibleNames(
              'mesh.modalityegressprofile',
              false,
              'Modalidad del Perfil de Egreso'
            )}`"
            size="md"
            hide-footer
          >
            <ModalityEgressProfileForm
              :modalityEgressProfile="row.item"
              @updated="slotUpdatedModality"
              :institution_id="row.item.school"
              :show_title="false"
            ></ModalityEgressProfileForm>
          </b-modal>
        </template>
      </GenericBTable>
      <div v-else>
        <br />
        <strong
          >No se a encontrado ninguna
          {{
            $getVisibleNames(
              "mesh.modalityegressprofile",
              false,
              "Modalidad del Perfil de Egreso"
            )
          }}.</strong
        >
      </div>
    </div>
    <b-modal
      :id="`new-modality-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames(
        'mesh.modalityegressprofile',
        false,
        'Modalidad del Perfil de Egreso'
      )}`"
      size="md"
    >
      <ModalityEgressProfileForm
        @created="slotCreatedModality"
        :institution_id="this.user.school"
      ></ModalityEgressProfileForm>
    </b-modal>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "ModalityEgressProfileView",
  components: {
    ModalityEgressProfileForm: () =>
      import(
        "@/components/mesh/CurricularResources/ModalityEgressProfile/ModalityEgressProfileForm"
      ),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    LimitStudyPlan: () =>
      import("@/components/mesh/EgressProfile/LimitStudyPlan"),
    AlternativeNameButton: () =>
      import("@/components/reusable/AlternativeNameButton"),
  },
  props: {
    input_search: {
      type: String,
      default: "",
    },

    display_id: {},
  },
  data() {
    return {
      // breadcrumb_items: [],
      sortBy: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      modalities_egress_profiles: names.MODALITIES_EGRESS_PROFILE,
      user_position: names.USER_POSITION,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions(
        "mesh.add_modalityegressprofile"
      );
      if (has_permission) return has_permission;
      else return false;
    },
    searchModality: function () {
      return this.modalities_egress_profiles
        .filter((item) => {
          return (
            item.name.toLowerCase().includes(this.input_search.toLowerCase()) ||
            item.description
              .toLowerCase()
              .includes(this.input_search.toLowerCase())
          );
        })
        .sort(function (a, b) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
    },
    modalitiesFields() {
      if (!this.allows_crud)
        return this.modalities_fields.filter(
          (field) => !field.requires_allows_crud
        );
      else return this.modalities_fields;
    },
    modalities_fields() {
      return [
        {
          key: "name",
          label: String(
            this.$getVisibleNames(
              "mesh.modalityegressprofile",
              true,
              "Modalidades del Perfil de Egreso"
            )
          ),
          sortable: true,
          tdClass: "text-left",
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
        },
        {
          key: "description",
          label: "Descripción",
          sortable: true,
          tdClass: "text-left",
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
        },
        {
          key: "actions",
          label: "",
          display_column: true,
          thClass: "noprint",
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    askForDeleteModality(modality_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.modalityegressprofile",
          false,
          "Modalidad del Perfil de Egreso"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_MODALITY_EGRESS_PROFILE, modality_id)
            .then(() => {
              toast(
                String(
                  this.$getVisibleNames(
                    "mesh.modalityegressprofile",
                    false,
                    "Modalidad del Perfil de Egreso"
                  ) + " eliminada."
                )
              );
            });
        }
      });
    },
    slotCreatedModality(response) {
      this.$bvModal.hide("new-modality-modal");
      this.$bvModal.show(`limit-study-plan-modal-${response.id}`);
    },
    slotUpdatedModality(modality) {
      this.$bvModal.hide(`edit-modality-modal-${modality.id}`);
    },
  },
  mounted() {
    // this.breadcrumb_items.push({
    //   text: "Definiciones Curriculares",
    //   active: true,
    // });
    // this.breadcrumb_items.push({
    //   text: this.$getVisibleNames(
    //     "mesh.modalityegressprofile",
    //     false,
    //     "Modalidad del Perfil de Egreso"
    //   ),
    //   active: true,
    // });
  },
  created() {
    if (this.user) {
      this.$store.dispatch(
        names.FETCH_MODALITIES_EGRESS_PROFILE,
        this.user.school
      );
    } else {
      this.$store.dispatch("logOut");
    }
  },
};
</script>
<style scoped>
th {
  background-color: var(--primary-color);
  border-radius: 6px;
  box-shadow: 1px 1px 3px rgb(72, 72, 72);
}

.title-header {
  padding: 0;
  margin-top: auto;
}
.search-modality {
  max-width: 50rem;
  margin-left: auto;
  margin-right: 18.5%;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
.edit-section {
  margin-top: -2%;
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 2.8em !important;
}
.breadcrumb-item.active {
  color: #2f9ae1;
}
.breadcrumb > li + li:before {
  color: #2f9ae1;
  content: "❯ ";
  padding: 0;
  padding-right: 0.65em;
}
</style>
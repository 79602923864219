var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('button',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_modalityegressprofile'),expression:"'mesh.add_modalityegressprofile'"}],staticClass:"btn btn-secondary btn-sm",on:{"click":function($event){return _vm.$bvModal.show(`new-modality-modal`)}}},[_vm._v(" Agregar "+_vm._s(_vm.$getVisibleNames( "mesh.modalityegressprofile", false, "Modalidad del Perfil de Egreso" ))+" ")]):_vm._e()])]),_c('h3',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.modalityegressprofile", false, "Modalidad del Perfil de Egreso" ))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'mesh.modalityegressprofile',"allows_crud":_vm.allows_crud}})],1),(_vm.searchModality.length > 0)?_c('GenericBTable',{attrs:{"items":_vm.modalities_egress_profiles,"pagination":_vm.modalities_egress_profiles.length,"fields":_vm.modalitiesFields,"filter":_vm.input_search,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"display_id":_vm.display_id,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(row){return [(_vm.allows_crud)?[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
              'Editar requisitos de horas y créditos'
            ),expression:"\n              'Editar requisitos de horas y créditos'\n            ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"m-0 p-0 mr-2",attrs:{"variant":"none"},on:{"click":function($event){return _vm.$bvModal.show(`limit-study-plan-modal-${row.item.id}`)}}},[_c('b-icon',{attrs:{"icon":"journal-text","scale":"1"}})],1),_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
              `Editar ${_vm.$getVisibleNames(
                'mesh.modalityegressprofile',
                false,
                'Modalidad del Perfil de Egreso'
              )}`
            ),expression:"\n              `Editar ${$getVisibleNames(\n                'mesh.modalityegressprofile',\n                false,\n                'Modalidad del Perfil de Egreso'\n              )}`\n            ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(`edit-modality-modal-${row.item.id}`)}}}),_c('button-delete',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
              `Eliminar ${_vm.$getVisibleNames(
                'mesh.modalityegressprofile',
                false,
                'Modalidad del Perfil de Egreso'
              )}`
            ),expression:"\n              `Eliminar ${$getVisibleNames(\n                'mesh.modalityegressprofile',\n                false,\n                'Modalidad del Perfil de Egreso'\n              )}`\n            ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.askForDeleteModality(row.item.id)}}})]:[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
              'Ver requisitos de horas y créditos'
            ),expression:"\n              'Ver requisitos de horas y créditos'\n            ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"m-0 p-0 mr-2",attrs:{"variant":"none"},on:{"click":function($event){return _vm.$bvModal.show(`limit-study-plan-modal-${row.item.id}`)}}},[_c('b-icon',{attrs:{"icon":"journal-text","scale":"1"}})],1)],_c('b-modal',{attrs:{"id":`limit-study-plan-modal-${row.item.id}`,"title":`${
            !_vm.allows_crud ? 'VER' : 'EDITAR'
          } REQUISITOS DE HORAS Y CRÉDITOS`,"size":"xl","hide-footer":""}},[_c('LimitStudyPlan',{attrs:{"modality_egress_profile_id":row.item.id,"allows_crud":_vm.allows_crud &&
              ((_vm.user_position &&
                [1, 2, 3].includes(_vm.user_position.position) &&
                [1, 2, 3].includes(_vm.user.groups[0])) ||
                _vm.user.is_superuser)}})],1),_c('b-modal',{attrs:{"id":`edit-modality-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'mesh.modalityegressprofile',
            false,
            'Modalidad del Perfil de Egreso'
          )}`,"size":"md","hide-footer":""}},[_c('ModalityEgressProfileForm',{attrs:{"modalityEgressProfile":row.item,"institution_id":row.item.school,"show_title":false},on:{"updated":_vm.slotUpdatedModality}})],1)]}}],null,false,4093297167)}):_c('div',[_c('br'),_c('strong',[_vm._v("No se a encontrado ninguna "+_vm._s(_vm.$getVisibleNames( "mesh.modalityegressprofile", false, "Modalidad del Perfil de Egreso" ))+".")])])],1),_c('b-modal',{attrs:{"id":`new-modality-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'mesh.modalityegressprofile',
      false,
      'Modalidad del Perfil de Egreso'
    )}`,"size":"md"}},[_c('ModalityEgressProfileForm',{attrs:{"institution_id":this.user.school},on:{"created":_vm.slotCreatedModality}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }